import variables from 'assets/scss/variables.scss';

import { Typography } from 'components/common';

interface Props {
  text?: string;
  className?: string;
  children?: React.ReactNode;
}

const Dialog = ({
  text,
  className,
  children,
}: Props) => {
  return (
    <div
      className="p-3"
      style={{
        backgroundColor: 'rgba(255, 0, 4, 0.05)',
        borderRadius: '8px',
        border: `1px solid ${variables.red2}`
      }}
    >
      <Typography inline style={{ fontWeight: 'bold' }}>
        Important:
        {' '}
      </Typography>

      {
        text && (
          <Typography inline>
            {text}
          </Typography>
        )
      }

      {children}
    </div>
  );
};

export default Dialog;
