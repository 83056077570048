import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import Smartcar from '@smartcar/auth';

import { APIStatus } from 'types';
import { LoadingSpinner } from 'components/LoadingSpinner';
import axios from 'axios';
import AddVirtualKeyBanner from '../Cars/AddVirtualKeyBanner';

const scope = [
  'required:read_vin', 'required:read_odometer', 'required:read_location', 'required:control_security',
  'required:read_battery', 'required:read_vehicle_info', 'required:read_extended_vehicle_info',
  'required:read_charge', 'required:control_charge', 'required:read_charge_records', 'required:read_tires',
  'required:read_thermometer', 'required:read_climate', 'required:control_climate', 'required:read_compass',
  'required:read_speedometer', 'required:read_security', 'required:control_charge', 'required:control_trunk'
];

const smartcar = new Smartcar({
  clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID,
  redirectUri: process.env.REACT_APP_SMARTCAR_OWNER_REDIRECT_URI,
  scope,
  mode: process.env.REACT_APP_SMARTCAR_MODE,
  // onComplete: (err: any, code: any, status: any) => {
  //   console.log('hell yeah', err, code, status);
  // },
});

const ConnectCars = () => {
  const location = useLocation();
  const [showVirtualKeyModal, setShowVirtualKeyModal] = useState(false);
  const [connectCarsStatus, setCarsConnectStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const token = queryParams.get('token');

    if (!token && !state) return;

    if (code && state) {
      setCarsConnectStatus({ loading: true, success: false, error: null });

      const api = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;

      const decodedState: any = JSON.parse(window.atob(state));

      const request = axios.create({
        headers: {
          Authorization: `Bearer ${decodedState.originalState}`,
        },
      });

      request
        .post(`${api}/admins/cars/smartcar/connect`, {
          code,
          redirect_uri: process.env.REACT_APP_SMARTCAR_OWNER_REDIRECT_URI,
        })
        .then(res => res.data)
        .then(() => {
          setCarsConnectStatus({ loading: false, success: true, error: null });

          setShowVirtualKeyModal(true);
        })
        .catch(error => {
          setCarsConnectStatus({ loading: false, success: false, error });
        });

      return;
    }

    smartcar.openDialog({
      forcePrompt: true,
      windowOptions: { width: '200px', height: '200px', top: 0, right: 0 },
      flags: ['tesla_auth:true'],
      state: token,
    });
  }, []);

  return (
    <div>
      <AddVirtualKeyBanner
        style={{ marginBottom: '12px' }}
        visible={showVirtualKeyModal}
        showBanner={false}
      />

      {
        connectCarsStatus.loading && (
          <LoadingSpinner />
        )
      }

      {
        connectCarsStatus.success && (
          <div>Connected successfully!</div>
        )
      }

      {
        connectCarsStatus.error && (
          <div>
            Please refresh the link the owner shared with you and try again.
          </div>
        )
      }
    </div>
  );
};

export default ConnectCars;
