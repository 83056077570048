import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Icon } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import Modal from 'react-modal';

import { Search } from 'assets/icons';
import { ownerActions, ownerSelectors, Cars } from 'redux/slices';
import { ICar, CarStatusEnum, APIStatus } from 'types';
import { InputField } from 'components/common/InputField';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Button, Typography } from 'components/common';
import CarCard from 'components/Owner/Cars/CarCard';
import AddVirtualKeyBanner from 'components/Owner/Cars/AddVirtualKeyBanner';
import * as api from 'api';

import variables from 'assets/scss/variables.scss';
import { OWNER_TOKEN_STORAGE } from '../../../constants';
import SmartcarConnect from '../SmartcarConnect';
import SmartcarUserDriverBanner from './SmartcarUserDriverBanner';
import { IOwner } from '../../../types';

const CarsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showYourCars, setShowYourCars] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [showConnectCarsModal, setShowConnectCarsModal] = useState(false);
  const [showVirtualKeyModal, setShowVirtualKeyModal] = useState(false);
  const cars = useSelector(ownerSelectors.selectCars) as Cars;
  const [car, setCar] = useState<ICar | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [smartcarConnectStatus, setSmartcarConnectStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;

  useEffect(() => {
    const connectParam = searchParams.get('connect');
    if (connectParam === 'true') {
      setShowConnectCarsModal(true);
    }
  }, [searchParams]);

  const connectCarsUrl = useMemo(() => {
    const token = localStorage.getItem(OWNER_TOKEN_STORAGE);
    return `${window.location.protocol}//${window.location.host}/owner/cars/connect?token=${token}`;
  }, []);

  const debouncedSearch = useCallback(
    debounce((searchText) => {
      dispatch(ownerActions.getCars.base({
        page: 1,
        per_page: 10,
        search: searchText,
      }));
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText, debouncedSearch]);

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      setSmartcarConnectStatus({ loading: true, success: false, error: null });

      const handleSmartcarConnect = async () => {
        try {
          await api.connectSmartcar(code);
          setSmartcarConnectStatus({ loading: false, success: true, error: null });
          toast.success('Cars connected successfully');
          setShowVirtualKeyModal(true);

          dispatch(ownerActions.getCars.base({
            page: 1,
            per_page: 10,
            search: searchText,
          }));
        } catch (error: any) {
          setSmartcarConnectStatus({
            loading: false,
            success: false,
            error: { code: error.response.status, message: error.response.data.error },
          });
          toast.error('Error connecting cars');
        }

        setSearchParams('');
      };

      handleSmartcarConnect();
    }
  }, [searchParams]);

  const navigateToEditCars = (car: ICar) => {
    navigate(`/owner/cars/${car.id}/edit`);
  };

  const handleLoadMore = () => {
    if (cars.status.loading) return;

    dispatch(ownerActions.getCars.base({
      page: cars.page! + 1,
      per_page: 10,
      search: searchText,
    }));
  };

  const renderCars = () => {
    if (cars.data.length === 0 && cars.status.success) {
      return (
        <div className="d-flex flex-column align-items-center mt-5 gap-3" style={{ width: '300px' }}>
          <Typography variant="h1" style={{ color: variables.gray2 }}>
            No Cars Listed
          </Typography>
          <Typography variant="body1" className="text-center" style={{ color: variables.gray2 }}>
            Get started by tapping the &quot;+&quot; at the top right and following the prompts 🚀
          </Typography>
        </div>
      );
    }

    if ((cars.status.loading && cars.page === 1) || smartcarConnectStatus.loading) {
      return (
        <LoadingSpinner />
      );
    }

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={cars.page! < cars.total_pages!}
        loader={<LoadingSpinner key={1} />}
        className="d-flex flex-column gap-3 w-100 mt-2"
      >
        {
          [...cars.data]
            .map((car) => (
              <div key={car.id}>
                <CarCard
                  car={car}
                  onConnectVirtualKey={() => {
                    setShowVirtualKeyModal(true);
                    setCar(car);
                  }}
                  onReconnectToSmartcar={() => {
                    setShowConnectCarsModal(true);
                    setCar(car);
                  }}
                />
              </div>
            ))
        }
      </InfiniteScroll>
    );
  };

  return (
    <div
      className="d-flex flex-column align-items-center w-100"
      style={{ padding: '26px' }}
    >
      <ToastContainer autoClose={2500} />

      <AddVirtualKeyBanner
        car={car!}
        style={{ marginBottom: '12px' }}
        visible={showVirtualKeyModal}
        showBanner={false}
        onAddACar={() => {
          setShowConnectCarsModal(true);
        }}
        fetchReviewCars
        onDismiss={() => {
          setShowVirtualKeyModal(false);
        }}
      />

      <Modal
        ariaHideApp={false}
        isOpen={showConnectCarsModal}
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            zIndex: 1000,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '325px',
            maxHeight: '90%',
            borderRadius: '8px',
            padding: '18px',
          },
        }}
        onRequestClose={() => setShowConnectCarsModal(false)}
      >
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => setShowConnectCarsModal(false)}
            style={{ width: '25px', height: '25px', padding: 0 }}
          >
            X
          </Button>
        </div>

        <div className="d-flex flex-column align-items-center gap-3 mt-3">
          <div
            className="d-flex flex-row w-100 text-center gap-1"
          >
            <div
              className="d-flex flex-row align-items-center justify-content-center"
              style={{
                width: '50%',
                backgroundColor: showYourCars ? variables.gray3 : variables.gray4,
                borderRadius: '8px',
                padding: '4px',
              }}
              onClick={() => setShowYourCars(true)}
            >
              <Typography
                variant="h3"
                className="m-0"
                style={{ color: variables.white }}
              >
                Your Cars
              </Typography>
            </div>

            <div
              className="d-flex flex-row align-items-center justify-content-center"
              style={{
                width: '50%',
                backgroundColor: showYourCars ? variables.gray4 : variables.gray3,
                borderRadius: '8px',
                padding: '4px',
              }}
              onClick={() => setShowYourCars(false)}
            >
              <Typography
                variant="h3"
                className="m-0"
                style={{ color: variables.white }}
              >
                Someone else’s car(s)
              </Typography>
            </div>
          </div>

          {
            showYourCars
              ? (
                <div className="d-flex flex-column align-items-center gap-2 mt-4 text-center">
                  <Typography variant="h1">
                    Adding Cars
                  </Typography>

                  <Typography>
                    These are cars you own directly (i.e. you are the main Tesla account owner).
                    If another Tesla owner shared app access with you to manage their cars, click
                    on &apos;Someone else’s car(s)&apos; above and follow the instructions.
                  </Typography>

                  <div>
                    <Typography variant="h2" style={{ textDecoration: 'underline' }}>
                      Important:
                    </Typography>

                    <Typography>
                      You&apos;ll need to enable
                      <b> ALL PERMISSIONS </b>
                      in order for your car to successfully connect
                    </Typography>
                  </div>

                  <SmartcarConnect
                    text="Let&apos;s Go!"
                    style={{ width: '80%' }}
                  />
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center gap-2 mt-2 text-center">
                  <Typography variant="h1">
                    Adding Cars
                  </Typography>

                  <Typography>
                    There are permissions that only the main Tesla account owner can grant.
                    If the vehicle owner shared Tesla app access with you, share the following link:
                  </Typography>

                  <Typography
                    style={{
                      wordBreak: 'break-all',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {connectCarsUrl}
                  </Typography>

                  <Button
                    onClick={async () => {
                      await navigator.clipboard.writeText(connectCarsUrl);
                      toast.success('Copied to clipboard');
                    }}
                  >
                    Copy link
                  </Button>

                  <Typography className="mt-3">
                    Have them open the link to connect the cars they own.
                  </Typography>

                  <Typography className="mt-2">
                    <b>Warning: </b>

                    If you connect a car where you are not the main account holder,
                    supercharging and tolls will not be calculated for your trips.
                  </Typography>
                </div>
              )
          }

          <a
            href="https://eonrides.notion.site/FAQ-30874a0bcebc4894819b5ecf62cf6b4f?pvs=4"
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: '12px', textAlign: 'center' }}
          >
            Have questions? Learn more about our integration here.
          </a>
        </div>
      </Modal>

      <div
        className="d-flex flex-column align-items-center w-100"
        style={{ maxWidth: '650px' }}
      >
        <div
          className="mb-2 d-flex flex-row align-items-center justify-content-space-around w-100 gap-2"
        >
          <InputField
            label="Search Cars"
            type="text"
            name="Search Cars"
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
            required
            startIcon={<Search />}
            style={{ width: '95%' }}
          />

          <Button
            variant="default"
            style={{
              width: '125px',
              height: '50px',
              padding: 0,
            }}
            className="d-flex align-items-center justify-content-center"
            onClick={() => setShowConnectCarsModal(true)}
            disabled={owner?.email === 'teslarents@resla.com'}
          >
            Add Cars
          </Button>
        </div>

        {renderCars()}
      </div>
    </div>
  );
};

export default CarsScreen;
