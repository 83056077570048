import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Button, Typography } from 'components/common';
import { Eon } from 'assets/icons';

interface Props {
  style?: any;
  visible?: boolean;
  onDismiss?: () => void;
}

const SmartcarUserDriverModal = ({
  style,
  visible = false,
  onDismiss,
}: Props) => {
  const [showModal, setShowModal] = useState(visible);

  useEffect(() => {
    setShowModal(visible);
  }, [visible]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={showModal}
      style={{
        overlay: {
          zIndex: 1000,
        },
        content: {
          zIndex: 1000,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '29px',
          width: '90%',
          maxWidth: '350px',
          borderRadius: '8px',
          overflow: 'scroll',
          maxHeight: '90%',
        },
      }}
      onRequestClose={() => {
        setShowModal(false);
        if (onDismiss) {
          onDismiss();
        }
      }}
    >
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            setShowModal(false);
            if (onDismiss) {
              onDismiss();
            }
          }}
          style={{ width: '25px', height: '25px', padding: 0 }}
        >
          X
        </Button>
      </div>

      <div className="d-flex flex-column align-items-center gap-3">
        <div>
          <Eon style={{ width: '70px', height: '70px' }} />
        </div>

        <Typography variant="h1" className="text-center" style={{ lineHeight: '35px' }}>
          Tesla Account Issue
        </Typography>

        <Typography className="text-center" style={{ lineHeight: '35px' }}>
          The car is currently connected to a shared driver profile on Tesla, not the owner profile.
          This means that critical information like supercharging records are not accessible.
          To fix, please have the owner of the car (the main Tesla account) reconnect.
        </Typography>

        <Button
          style={{ width: '300px' }}
          onClick={() => {
            window.open('/owner/cars?connect=true', '_blank');
          }}
        >
          Reconnect
        </Button>
      </div>
    </Modal>
  );
};

export default SmartcarUserDriverModal;
