import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { CSSObjectWithLabel } from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment-timezone';

import '@mobiscroll/react/dist/css/mobiscroll.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Search } from 'assets/icons';
import { getBackgroundImageUri } from 'helpers/cars';
import { ownerActions, ownerSelectors, CalendarReservations, Cars } from 'redux/slices';
import { Button, InputField, Typography } from 'components/common';
import { ICar, IReservation, ILocation, IOwner } from 'types';
import { getResColor } from 'helpers/reservations';
import variables from 'assets/scss/variables.scss';
import CreateBlock from './CreateBlock';

import {
  Eventcalendar,
  MbscEventCreatedEvent,
  MbscEventClickEvent,
  MbscSelectedDateChangeEvent,
  MbscResource,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscPageLoadedEvent,
  MbscEventCreateFailedEvent,
} from '../../../@mobiscroll/react'; // eslint-disable-line
import { EventcalendarBase } from '../../../@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar';

import './styles.scss';

const Calendar = () => {
  const dispatch = useDispatch();
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const calendarReservations = useSelector(ownerSelectors.selectCalendarReservations) as CalendarReservations;
  const locations = useSelector(ownerSelectors.selectCalendarLocations) as ILocation[];
  const cars = useSelector(ownerSelectors.selectCars) as Cars;

  const [dateType, setDateType] = useState<'month' | 'week'>('month');
  const [searchText, setSearchText] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);
  const [showCreateBlockModal, setShowCreateBlockModal] = useState(false);
  const [firstDay, setFirstDay] = useState<Date | null>(null);
  const [lastDay, setLastDay] = useState<Date | null>(null);
  const firstDayTimestamp = firstDay?.getTime();
  const lastDayTimestamp = lastDay?.getTime();

  const [newResData, setNewResData] = useState<any>({});

  useEffect(() => {
    dispatch(ownerActions.getAllCars.base());
    dispatch(ownerActions.getLocations.base());
  }, []);

  useEffect(() => {
    if (!firstDay || !lastDay) return;

    dispatch(ownerActions.getCalendarReservations.base({
      startDate: moment(firstDay),
      endDate: moment(lastDay),
      city: selectedLocation?.city,
    }));
  }, [firstDayTimestamp, lastDayTimestamp, selectedLocation]);

  useEffect(() => {
    setShowCreateBlockModal(!!newResData.carId);
  }, [newResData]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCalendarLoad = (args: MbscPageLoadedEvent, inst: EventcalendarBase) => {
    setFirstDay(args.firstDay);
    setLastDay(args.lastDay);

    // TODO: remove
    // console.log('args.firstDay', args.firstDay);
    // console.log('args.lastDay', args.lastDay);
  };

  const createBlockCar = useMemo((): ICar | undefined => {
    return cars.data.find((car: ICar) => car.id === parseInt(newResData.carId, 10));
  }, [newResData]);

  const view = useMemo((): MbscEventcalendarView => {
    return {
      timeline: {
        type: dateType,
        resolution: 'day',
        currentTimeIndicator: true,
      }
    };
  }, [dateType]);

  const getResourceId = (car: ICar): string => {
    return `${car.default_location.city}__${car.id}`;
  };

  const resources = useMemo((): MbscResource[] => {
    const cityMap: { [key: string]: ICar[] } = {};
    cars.data.forEach((car: ICar) => {
      const carCity = car.default_location?.city;
      if (!cityMap[carCity]) {
        cityMap[carCity] = [car];
      } else if (cityMap[carCity].find(c => c.id === car.id) === undefined) {
        cityMap[carCity].push(car);
      }
    });

    const resources = Object.keys(cityMap)
      .map((city: string) => ({
        id: `gro${city}`,
        name: city,
        color: '#fdf500',
        eventCreation: false,
        children: cityMap[city]
          .filter((car: ICar) => (
            car.is_approved
          ))
          .filter((car: ICar) => (
            car.license_plate?.toLowerCase().includes(searchText.toLowerCase())
            || car.model?.display_name.toLowerCase().includes(searchText.toLowerCase())))
          .filter((car: ICar) => {
            if (selectedLocation) {
              return car.default_location.city === selectedLocation.city;
            }
            return car;
          })
          .map((car: ICar) => ({
            id: getResourceId(car),
            name: `${car.license_plate} ${car.model?.display_name}`,
            img_uri: getBackgroundImageUri(car),
            background: car.on_subscription ? variables.blue3 : '',
          })),
      }));

    return resources.filter((res: MbscResource) => res.children && res.children.length > 0);
  }, [cars, calendarReservations, searchText, dateType, selectedLocation]);

  const events = useMemo((): MbscCalendarEvent[] => {
    return calendarReservations.data.map((res: IReservation) => {
      return {
        id: res.id,
        start: moment(res.start_time),
        end: moment(res.end_time),
        title: `${res.customer_name} #${res.id}`,
        resource: getResourceId(res.car),
        color: getResColor(res),
        tooltip: `
          Rented for ${res.num_days} days by ${res.customer_name}\n
          Current status is ${res.status}
        `,
      };
    });
  }, [calendarReservations, dateType]);

  const handleEventCreate = (args: MbscEventCreatedEvent, inst: EventcalendarBase) => {
    const resource = args.event.resource as string;
    if (!resource) return;

    const carId = resource.split('__')[1];

    if (carId) {
      setNewResData({
        startDate: moment(args.event.start),
        endDate: moment(args.event.end),
        carId,
      });
    }

    setTimeout(() => {
      inst.setEvents(events);
    }, 500);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEventCreateFailed = (args: MbscEventCreateFailedEvent, inst: EventcalendarBase) => {
    if (args.invalid) {
      toast.error('An error occured.');
    } else if (args.overlap) {
      toast.error('This car is already rented for this date.');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEventClick = (args: MbscEventClickEvent, inst: EventcalendarBase) => {
    if (owner?.is_fully_managed) return;

    window.open(`/owner/reservations/${args.event.id}/edit`, '_blank');
  };

  const handleDateChange = (args: MbscSelectedDateChangeEvent, inst: EventcalendarBase) => {
    const startDate: Date | undefined = inst._firstDay;
    const endDate: Date | undefined = inst._lastDay;
    if (!startDate || !endDate) return;

    dispatch(ownerActions.getCalendarReservations.base({
      startDate: moment(args.date),
      endDate: moment(args.date).add(1, dateType),
      city: selectedLocation?.city,
    }));
  };

  const handleSelectLocation = (option: any) => {
    if (!option) {
      setSelectedLocation(null);
      return;
    }

    const location = locations.find(loc => loc.city === option.value);

    if (!location) return;

    dispatch(ownerActions.getCalendarReservations.base({
      startDate: moment(firstDay),
      endDate: moment(lastDay),
      city: location.city,
    }));

    setSelectedLocation(location);
  };

  // const handleCreateRes = () => {
  //   navigate('/owner/reservations/create', { ...newResData });
  // };

  const renderResource = (resource: any) => {
    return (
      <div
        onClick={() => {
          if (!resource.img_uri) return;

          window.open(`/owner/cars/${resource.id.split('__')[1]}/edit`, '_blank');
        }}
        style={{
          ...(resource.img_uri ? { color: 'blue', textDecoration: 'underline' } : {}),
        }}
      >
        {
          resource.img_uri && (
            <img
              src={resource.img_uri}
              alt="car"
              className="d-flex"
              style={{ height: 'auto', width: '100px' }}
            />
          )
        }
        <div>{resource.name}</div>
        <div>{resource.title}</div>
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-center mt-3 flex-column px-3 gap-3">
      <ToastContainer autoClose={2500} />
      <div style={{ width: '250px' }}>
        <Select
          onChange={handleSelectLocation}
          options={locations.map(loc => ({ value: loc.city, label: loc.city }))}
          placeholder="Select a Location"
          isSearchable
          styles={{ menu: (base, props) => ({ ...base, zIndex: 9999 } as CSSObjectWithLabel) }}
          isClearable
        />
      </div>

      <div
        className="d-flex justify-content-space-between"
      >
        <InputField
          label="Search calendar"
          type="text"
          name="Search calendar"
          value={searchText}
          onChange={(e: any) => setSearchText(e.target.value)}
          startIcon={<Search />}
          style={{ maxWidth: '250px' }}
        />

        <Button
          onClick={() => setDateType(dateType === 'month' ? 'week' : 'month')}
        >
          {dateType.toUpperCase()}
        </Button>
      </div>

      <div className="d-flex flex-column">
        <div>
          <Typography>
            {`All times are in ${moment().tz(moment.tz.guess()).format('z')}`}
          </Typography>

          <div className="d-flex flex-row gap-2">
            <div
              style={{ width: '20px', height: '20px', backgroundColor: variables.blue3, borderRadius: '10px' }}
            />
            <Typography>
              On subscription
            </Typography>
          </div>
        </div>

        {
          calendarReservations.status.loading && (
            <div className="align-self-center" style={{ width: '100px' }}>Loading...</div>
          )
        }

        <Eventcalendar
          theme="ios"
          themeVariant="light"
          view={view}
          data={events}
          resources={resources}
          width="100%"
          dragToCreate={!owner?.is_fully_managed}
          onEventCreated={handleEventCreate}
          onEventCreateFailed={handleEventCreateFailed}
          onEventDoubleClick={handleEventClick}
          onSelectedDateChange={handleDateChange}
          onPageLoaded={handleCalendarLoad}
          eventOverlap={false}
          renderResource={renderResource}
        />
      </div>

      <CreateBlock
        isOpen={showCreateBlockModal}
        startDate={newResData.startDate}
        endDate={newResData.endDate}
        car={createBlockCar}
        onClose={() => setShowCreateBlockModal(false)}
      />
    </div>
  );
};

export default Calendar;
