import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Button, Typography } from 'components/common';
import { Eon } from 'assets/icons';
import { ICar } from 'types';
import SmartcarUserDriverModal from '../SmartcarUserDriverModal';

interface Props {
  car: ICar;
  style?: any;
  visible?: boolean;
}

const SmartcarUserDriverBanner = ({
  car,
  style,
  visible = false,
}: Props) => {
  const [showModal, setShowModal] = useState(visible);

  useEffect(() => {
    setShowModal(visible);
  }, [visible]);

  if (car.smartcar_user_access_type !== 'DRIVER' || car.is_resla_car) {
    return null;
  }

  return (
    <>
      <div className="d-flex flex-row gap-2" style={style}>
        <div style={{ fontSize: '20px' }}>
          ⚠️
        </div>
        <div>
          No supercharging
        </div>

        <Button
          className="m-0 p-0"
          style={{ minWidth: '150px', height: '35px' }}
          onClick={() => setShowModal(true)}
        >
          Fix Issue
        </Button>
      </div>

      <SmartcarUserDriverModal visible={showModal} />
    </>
  );
};

export default SmartcarUserDriverBanner;
