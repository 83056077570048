import { ICleaner } from 'types/profile';

export interface ILocation {
  id: number;
  city: string;
  address: string;
  state: string;
  state_short: string;
  latitude: number;
  longitude: number;
  gmt_offset: string;
  zipcode: string;
  timezone: string;
  admin_id: number;
  pickup_instructions: string;
}

export interface ICarState {
  odometer: number;
  usable_battery_level: number;
  lat: string;
  lon: string
}

export enum CarStatusEnum {
  MissingInformation = 0,
  NotConnected = 1,
  AwaitingApproval = 2,
  Approved = 3,
  SoftDeleted = 4,
}

export interface ITrim {
  id: number;
  name: string;
  display_name: string;
}

export interface IModel {
  id: number;
  name: string;
  display_name: string;
  trims: ITrim[];
}

export interface ICar {
  id: number;
  description: string;
  default_location: ILocation;
  autopilot_type: string;
  battery_range: number;
  daily_rate: number;
  manual_daily_rate: number;
  is_available: boolean;
  model_name: string;
  model_type: string;
  model: IModel;
  trim?: ITrim;
  num_seats: number;
  thumbnails: string[];
  zero_to_sixty: number;
  color: string;
  interior_color: string;
  supports_delivery: boolean;
  return_address: string;
  delivery_address: string;
  delivery_cost: number;
  delivery_distance: number;
  gmt_offset: string;
  license_plate: string;
  vin: string;
  is_smartcar_connected: boolean;
  is_approved: boolean;
  is_awaiting_approval: boolean;
  status: CarStatusEnum;
  car_state: ICarState;
  use_automated_pricing: boolean;
  minimum_daily_rate: number;
  maximum_daily_rate: number;
  one_day_multiplier: number;
  allow_car_substitution: boolean;
  hours_between_trips: number;
  min_hours_in_advance: number;
  min_days_to_book: number;
  cleaner_id: number;
  delivery_person_phone: string;
  license_plate_state: string;
  pin_to_drive: string;
  glovebox_pin: string;
  is_insured: boolean;
  management_commission_percentage: number;
  cleaner?: ICleaner;
  max_delivery_distance: number;
  is_fully_managed: boolean;
  smartcar_virtual_key_connected: boolean;
  requires_smartcar_migration: boolean;
  weekly_discount: number;
  monthly_discount: number;
  soft_delete: boolean;
  on_subscription: boolean;
  smartcar_user_access_type: 'DRIVER' | 'OWNER';
  is_resla_car: boolean;
  allow_subscription: boolean;
  vehicle_year: number;
}

export interface IStoreLocations {
  data: [ILocation] | [];
  isLoading: boolean;
}

export interface IStoreCars {
  data: [ICar] | [];
  isLoading: boolean;
}

export enum SortValue {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SortableFields {
  daily_rate = 'daily_rate'
}

export interface ISortFilters {
  id: number;
  field: SortableFields;
  value: SortValue;
  title: string;
}
