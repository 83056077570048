import { useState } from 'react';
import { Button, Card, Typography } from 'components/common';
import { ICar, CarStatusEnum } from 'types';

import variables from 'assets/scss/variables.scss';
import { getBackgroundImageUri } from 'helpers/cars';
import { useNavigate } from 'react-router-dom';
import SmartcarUserDriverModal from '../SmartcarUserDriverModal';

interface Props {
  car: ICar;
  onConnectVirtualKey?: () => void;
  onReconnectToSmartcar?: () => void;
}

const CarCard = ({
  car,
  onConnectVirtualKey,
  onReconnectToSmartcar,
}: Props) => {
  const navigate = useNavigate();
  const backgroundImageURI = getBackgroundImageUri(car);
  const [showReconnectModal, setShowReconnectModal] = useState(false);
  let onlineStatusColor = variables.green2;
  let onlineStatusText = 'Connected';
  let liveStatusColor = variables.red2;
  let liveStatusText = 'Not Live';

  if (car.status === CarStatusEnum.NotConnected) {
    onlineStatusColor = variables.red2;
    onlineStatusText = 'Disconnected';
  } else if (car.status === CarStatusEnum.AwaitingApproval) {
    onlineStatusColor = variables.blue4;
    onlineStatusText = 'Awaiting Approval';
  } else if (car.status === CarStatusEnum.Approved) {
    onlineStatusColor = variables.green2;
    onlineStatusText = 'Connected';
  }

  if (car.smartcar_user_access_type === 'DRIVER' && !car.is_resla_car) {
    liveStatusColor = variables.red2;
    liveStatusText = 'Not Live';
  } else if (car.smartcar_user_access_type !== 'DRIVER' && car.status === CarStatusEnum.NotConnected) {
    liveStatusColor = variables.red2;
    liveStatusText = 'Not Live';
  } else if (!car.smartcar_virtual_key_connected && !car.is_resla_car) {
    liveStatusColor = variables.red2;
    liveStatusText = 'Not Live';
  } else if (car.status === CarStatusEnum.AwaitingApproval) {
    liveStatusColor = variables.red2;
    liveStatusText = 'Not Live';
  } else if (car.status === CarStatusEnum.SoftDeleted) {
    liveStatusColor = variables.red2;
    liveStatusText = 'Not Live';
  } else {
    liveStatusColor = variables.green2;
    liveStatusText = 'Live';
  }

  return (
    <>
      <Card
        className="w-100 p-0"
        style={{ backgroundColor: 'white', border: '1px solid #D5D5D5', overflow: 'hidden' }}
      >
        <div className="d-flex flex-column flex-md-direction-row gap-2">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ backgroundColor: 'rgba(249, 249, 253, 1)' }}
          >
            <img
              src={backgroundImageURI}
              alt="car"
              style={{ objectFit: 'contain', height: '130px' }}
            />
          </div>

          <div className="w-100">
            <div className="d-flex flex-column gap-3 p-2 pt-1">
              <div className="d-flex justify-content-between">
                <div>
                  <Typography variant="body2" className="m-0">
                    {`${car.vehicle_year}
                    ${car.model?.display_name && car.trim?.display_name ? `${car.model.display_name} - ${car.trim.display_name}` : ''}`}
                  </Typography>

                  <Typography variant="body3" className="m-0">
                    {`License: ${car.license_plate || ''} | VIN: ${car.vin || ''}`}
                  </Typography>

                  <Typography variant="body3" className="m-0">
                    {car.default_location?.address}
                  </Typography>
                </div>

                <div>
                  <Button
                    variant="default"
                    onClick={() => {
                      navigate(`/owner/cars/${car.id}/edit`);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>

              <div>
                <Typography variant="body2" className="m-0" style={{ color: onlineStatusColor }}>
                  {onlineStatusText}
                </Typography>

                <Typography variant="body2" className="m-0" style={{ color: liveStatusColor }}>
                  {liveStatusText}
                </Typography>
              </div>

              {
                ((
                  car.smartcar_user_access_type === 'DRIVER' && !car.is_resla_car
                )
                || (
                  car.smartcar_user_access_type !== 'DRIVER' && car.status === CarStatusEnum.NotConnected
                ) || (
                  !car.smartcar_virtual_key_connected && !car.is_resla_car
                ) || (
                  car.status === CarStatusEnum.SoftDeleted
                ) || (
                  liveStatusText === 'Not Live'
                )) && (
                  <div className="d-flex flex-column flex-wrap">
                    <Typography>
                      Todo:
                    </Typography>

                    <div className="d-flex flex-row gap-2">
                      {
                        car.smartcar_user_access_type === 'DRIVER' && !car.is_resla_car && (
                          <Button
                            variant="primary"
                            onClick={() => {
                              setShowReconnectModal(true);
                            }}
                          >
                            Reconnect as Owner
                          </Button>
                        )
                      }

                      {
                        car.smartcar_user_access_type !== 'DRIVER' && car.status === CarStatusEnum.NotConnected && (
                          <Button
                            variant="primary"
                            onClick={onReconnectToSmartcar}
                          >
                            Reconnect
                          </Button>
                        )
                      }

                      {
                        !car.smartcar_virtual_key_connected && !car.is_resla_car && (
                          <Button
                            variant="primary"
                            onClick={onConnectVirtualKey}
                          >
                            Create an Eon Key
                          </Button>
                        )
                      }

                      {
                        car.status === CarStatusEnum.SoftDeleted && (
                          <Button
                            variant="primary"
                            onClick={() => {
                              navigate(`/owner/cars/${car.id}/edit`);
                            }}
                          >
                            Relist Car
                          </Button>
                        )
                      }

                      {
                        car.status !== CarStatusEnum.SoftDeleted
                        && car.status !== CarStatusEnum.AwaitingApproval
                        && car.status !== CarStatusEnum.Approved && (
                          <Button
                            variant="primary"
                            onClick={() => {
                              navigate(`/owner/cars/${car.id}/edit`);
                            }}
                          >
                            Submit Car Details
                          </Button>
                        )
                      }
                    </div>

                  </div>
                )
              }

            </div>
          </div>
        </div>
      </Card>

      <SmartcarUserDriverModal
        visible={showReconnectModal}
        onDismiss={() => {
          setShowReconnectModal(false);
        }}
      />
    </>
  );
};

export default CarCard;
