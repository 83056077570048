import { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import variables from 'assets/scss/variables.scss';
import { Button, Card, Typography } from 'components/common';
import { ICar, APIStatus } from 'types';
import * as api from 'api';
import Dialog from 'components/Owner/Dialog';
import { LinearProgressBar } from 'components/TopBar/LinearProgressBar';
import cx from 'classnames';
import CarCard from 'components/Owner/Cars/CarCard';

interface Props {
  car?: ICar;
  onSuccess?: (car: ICar) => void;
  style?: any;
  visible?: boolean;
  showBanner?: boolean;
  fetchReviewCars?: boolean;
  onAddACar?: () => void;
  onDismiss?: () => void;
}

const AddVirtualKeyBanner = ({
  car,
  onSuccess,
  style,
  visible = false,
  showBanner = true,
  fetchReviewCars = false,
  onAddACar,
  onDismiss,
}: Props) => {
  const modalContentRef = useRef<HTMLDivElement | null>(null);

  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

  const [currCar, setCurrCar] = useState<ICar | null>(car || null);
  const [showVirtualKeyModal, setShowVirtualKeyModal] = useState(visible);
  const [showConnectSteps, setShowConnectSteps] = useState(false);
  const [showLastStep, setShowLastStep] = useState(false);
  const [showReviewCars, setShowReviewCars] = useState(false);
  const [reviewCars, setReviewCars] = useState<ICar[]>([]);
  const [reviewCarsStatus, setReviewCarsStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [verifyKeyStatus, setVerifyKeyStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  const [dots, setDots] = useState(0);

  useEffect(() => {
    if (modalContentRef.current && (showConnectSteps || showLastStep || showReviewCars)) {
      modalContentRef.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [showConnectSteps, showLastStep, showReviewCars]);

  useEffect(() => {
    setShowVirtualKeyModal(visible);
  }, [visible]);

  useEffect(() => {
    if (car) {
      setCurrCar(car);
    }
  }, [car]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots % 3) + 1);
    }, 500);

    return () => {
      clearInterval(intervalId); // Clean up the interval on unmount
    };
  }, []);

  const getReviewCars = async () => {
    try {
      setReviewCarsStatus({ loading: true, success: false, error: null });

      const { data } = await api.getOwnerUnconnectedCars();

      setReviewCars(data);

      setReviewCarsStatus({ loading: false, success: true, error: null });
    } catch (error: any) {
      setReviewCarsStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
    }
  };

  useEffect(() => {
    if (showReviewCars) {
      getReviewCars();
    }
  }, [showReviewCars]);

  if (currCar?.smartcar_virtual_key_connected || currCar?.is_resla_car) return null;

  const handleVerifyVirtualKey = async () => {
    try {
      setVerifyKeyStatus({ loading: true, success: false, error: null });

      await api.verifyOwnerCarVirtualKey(car!.id);

      setVerifyKeyStatus({ loading: false, success: true, error: null });

      // eslint-disable-next-line no-unused-expressions
      onSuccess && onSuccess(car!);

      toast.success('Virtual key successfully connected');

      setTimeout(() => {
        setShowVirtualKeyModal(false);
      }, 2000);
    } catch (error: any) {
      setVerifyKeyStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error('Failed to verify virtual key.');
    }
  };

  const renderBody = () => {
    if (showReviewCars) {
      return (
        <div className="d-flex flex-column gap-4">
          <div>
            <Typography variant="h2">
              Review Your Cars
            </Typography>
          </div>

          {
            reviewCars
              .filter((c) => currCar ? c.id !== currCar.id : true)
              .map((reviewCar) => {
                return (
                  <CarCard
                    key={reviewCar.id}
                    car={reviewCar}
                    onConnectVirtualKey={() => {
                      setCurrCar(reviewCar);
                      setShowReviewCars(false);
                      setShowLastStep(false);
                      setShowConnectSteps(false);
                    }}
                    onReconnectToSmartcar={() => {
                      setCurrCar(reviewCar);
                      setShowReviewCars(false);
                      setShowLastStep(false);
                      setShowConnectSteps(false);
                    }}
                  />
                );
              })
          }

          <div className="d-flex flex-row justify-content-between">
            <Button
              variant="default"
              onClick={() => {
                onAddACar?.();
              }}
            >
              Add A Car
            </Button>

            <Button
              variant="primary"
              onClick={() => {
                setShowReviewCars(true);
              }}
            >
              Finish
            </Button>
          </div>
        </div>
      );
    }

    if (showLastStep) {
      return (
        <div className="d-flex flex-column gap-4">
          <div>
            <div className="d-flex flex-row gap-1">
              <Typography variant="h2" inline>
                Create & Connect Phone Key
                {' '}
              </Typography>

              <Typography variant="h2" inline>
                {'(est time <1min)'}
              </Typography>
            </div>

            <Typography variant="body2">
              {`${currCar?.model?.display_name} - ${currCar?.trim?.display_name} | License: ${currCar?.license_plate || ''} | Vin: ${currCar?.vin}`}
            </Typography>
          </div>

          <div className="d-flex justify-content-center">
            <LinearProgressBar
              progress={100}
              icons={[
                { name: 'key', text: 'Step 1: Create a Phone Key (Must be near vehicle)' },
                { name: 'key', text: 'Step 2: Connect to Eon' },
              ]}
              barHeight={2}
            />
          </div>

          <div style={{ marginTop: '100px' }}>
            <Dialog>
              <div className="d-flex flex-column">
                <Typography variant="body2" inline>
                  • You need to complete these steps for each car individually.
                </Typography>

                <Typography variant="body2" inline>
                  • 2020 and earlier Model S and X will not require an Eon Key to keep working at this time.
                </Typography>
              </div>
            </Dialog>

            <div
              className="p-3 mt-2 d-flex gap-2 flex-column"
              style={{ border: '1px solid #D5D5D5', borderRadius: '8px' }}
            >
              <Typography variant="body2" style={{ color: '#3370BA' }}>
                Step 1
              </Typography>

              <div>
                <Typography variant="body2" style={{ fontWeight: 'bold' }} inline>
                  From the same mobile device that you have created a phone key,
                  {' '}
                </Typography>

                <Typography variant="body2" inline>
                  navigate to the car you want to connect on the Tesla app.
                  {' '}
                </Typography>
              </div>

              <div
                className={
                  cx(
                    'd-flex align-items-center justify-content-center gap-3',
                    windowWidth < parseInt(variables.sizemd, 10) ? 'flex-column' : 'flex-row',
                  )
                }
              >
                <img
                  src="/images/virtual-key/setup-virtual-key/tutorial.gif"
                  alt="setup-phone-key-1"
                  style={{ width: windowWidth < parseInt(variables.sizemd, 10) ? '100%' : '50%' }}
                />
              </div>

              <Typography variant="body3">
                {"Note: You can swipe across or navigate by going to My Products > Car's Name"}
              </Typography>
            </div>

            <div
              className="p-3 mt-2 d-flex gap-2 flex-column"
              style={{ border: '1px solid #D5D5D5', borderRadius: '8px' }}
            >
              <Typography variant="body2" style={{ color: '#3370BA' }}>
                Step 2
              </Typography>

              <div>
                <Typography variant="body2" inline>
                  Tap on this button to create an
                  {' '}
                </Typography>

                <Typography variant="body2" style={{ fontWeight: 'bold' }} inline>
                  Eon Key
                  {' '}
                </Typography>

                <Typography variant="body2" inline>
                  and follow the prompts.
                </Typography>
              </div>

              <Button
                variant="primary"
                onClick={() => {
                  window.open('https://www.tesla.com/_ak/eon.app.car', '_blank');
                }}
              >
                Create Eon Key
              </Button>

              <Typography variant="body3">
                Note: First make sure you have 'Phone as a key' set up on your car(s).
                If you don't have this, go back to 'Create a Phone Key'.
              </Typography>

              <Typography variant="body3">
                Make sure you've navigated to the correct car in your Tesla app before clicking on this link
                - this will link the car you currently have open in the Tesla app
              </Typography>
            </div>

            <div
              className="p-3 mt-2 d-flex gap-2 flex-column"
              style={{ border: '1px solid #D5D5D5', borderRadius: '8px' }}
            >
              <Typography variant="body2" style={{ color: '#3370BA' }}>
                Step 3
              </Typography>

              <Typography variant="body2">
                Allow Eon to access to your vehicle and approve all permissions.
              </Typography>

              <div
                className={
                  cx(
                    'd-flex align-items-center justify-content-center gap-3',
                    windowWidth < parseInt(variables.sizemd, 10) ? 'flex-column' : 'flex-row',
                  )
                }
              >
                <img
                  src="/images/virtual-key/setup-virtual-key/1.png"
                  alt="setup-virtual-key-1"
                  style={{ width: windowWidth < parseInt(variables.sizemd, 10) ? '100%' : '50%' }}
                />

                <img
                  src="/images/virtual-key/setup-virtual-key/2.png"
                  alt="setup-virtual-key-2"
                  style={{ width: windowWidth < parseInt(variables.sizemd, 10) ? '100%' : '50%' }}
                />
              </div>
            </div>

            <div
              className="p-3 mt-2 d-flex gap-2 flex-column"
              style={{ border: '1px solid #D5D5D5', borderRadius: '8px' }}
            >
              <Typography variant="body2" style={{ color: '#3370BA' }}>
                Step 4
              </Typography>

              <Typography variant="body2">
                Once you've successfully granted access to Eon, tap "Test Connection" below to ensure
                your Eon Key is working.
              </Typography>

              <Button
                variant="primary"
                onClick={() => {
                  handleVerifyVirtualKey();
                }}
              >
                Test Connection
              </Button>

              {
                verifyKeyStatus.loading && (
                  <Typography>
                    Verifying
                    {'.'.repeat(dots)}
                  </Typography>
                )
              }
            </div>

            <div
              className="p-3 mt-2 d-flex gap-2 flex-column"
              style={{ border: '1px solid #D5D5D5', borderRadius: '8px' }}
            >
              <Typography variant="body2" style={{ color: '#3370BA' }}>
                Step 5
              </Typography>

              <div>
                <Typography variant="body2" inline>
                  To connect another vehicle,
                  {' '}
                </Typography>

                <Typography variant="body2" style={{ fontWeight: 'bold' }} inline>
                  navigate to that specific vehicle in the Tesla app
                </Typography>

                <Typography variant="body2" inline>
                  , and restart this tutorial from step 1.
                </Typography>
              </div>
            </div>
          </div>

          <div>
            <Typography style={{ color: 'rgba(51, 112, 186, 1)' }}>
              Additional questions? Text or call our support team at 857-399-0442
            </Typography>
          </div>

          <div className="d-flex flex-row justify-content-between">
            <Button
              variant="default"
              onClick={() => {
                setShowLastStep(false);
              }}
            >
              Back
            </Button>

            <Button
              variant="primary"
              onClick={() => {
                if (fetchReviewCars) {
                  setShowReviewCars(true);
                } else {
                  setShowVirtualKeyModal(false);
                }
              }}
            >
              {fetchReviewCars ? 'Next' : 'Finish'}
            </Button>
          </div>
        </div>
      );
    }

    if (showConnectSteps) {
      return (
        <div className="d-flex flex-column gap-4">
          <div>
            <div className="d-flex flex-row gap-1">
              <Typography variant="h2" inline>
                Create & Connect Phone Key
                {' '}
              </Typography>

              <Typography variant="h2" inline>
                {'(est time <1min)'}
              </Typography>
            </div>

            <Typography variant="body2">
              {`${currCar?.model?.display_name} - ${currCar?.trim?.display_name} | License: ${currCar?.license_plate || ''} | Vin: ${currCar?.vin}`}
            </Typography>
          </div>

          <div className="d-flex justify-content-center">
            <LinearProgressBar
              progress={0}
              icons={[
                { name: 'key', text: 'Step 1: Create a Phone Key (Must be near vehicle)' },
                { name: 'key', text: 'Step 2: Connect to Eon' },
              ]}
              barHeight={2}
            />
          </div>

          <div style={{ marginTop: '100px' }}>
            <Dialog text="You need to complete these steps for each car individually." />

            <div
              className="p-3 mt-2 d-flex gap-2 flex-column"
              style={{ border: '1px solid #D5D5D5', borderRadius: '8px' }}
            >
              <Typography variant="body2" style={{ color: '#3370BA' }}>
                Step 1
              </Typography>

              <div>
                <Typography variant="body2" inline>
                  Open the Tesla app and log in using the account of the primary owner*.
                  Go to 'My product' and then open the car you want to connect
                  {' '}
                </Typography>

                <Typography variant="body2" style={{ fontWeight: 'bold' }} inline>
                  {"(My products > Car's name)."}
                </Typography>
              </div>

              <Typography variant="body3">
                *Note: Only the main owner account (as opposed to a shared driver account)
                can create Phone Keys, so make sure you're signed into the primary account of the car you are connecting.
              </Typography>

              <div
                className={
                  cx(
                    'd-flex align-items-center justify-content-center gap-3',
                    windowWidth < parseInt(variables.sizemd, 10) ? 'flex-column' : 'flex-row',
                  )
                }
              >
                <img
                  src="/images/virtual-key/my-product/1.png"
                  alt="my-product-1"
                  style={{ width: windowWidth < parseInt(variables.sizemd, 10) ? '100%' : '50%' }}
                />

                <img
                  src="/images/virtual-key/my-product/2.png"
                  alt="my-product-2"
                  style={{ width: windowWidth < parseInt(variables.sizemd, 10) ? '100%' : '50%' }}
                />
              </div>
            </div>

            <div
              className="p-3 mt-2 d-flex gap-2 flex-column"
              style={{ border: '1px solid #D5D5D5', borderRadius: '8px' }}
            >
              <Typography variant="body2" style={{ color: '#3370BA' }}>
                Step 2
              </Typography>

              <div>
                <Typography variant="body2" inline>
                  While inside or near the car, open the Tesla app and tap
                  {' '}
                </Typography>

                <Typography variant="body2" style={{ fontWeight: 'bold' }} inline>
                  Set Up Phone Key
                  {' '}
                </Typography>

                <Typography variant="body2" inline>
                  or navigate to:
                  {' '}
                </Typography>

                <Typography variant="body2" style={{ fontWeight: 'bold' }} inline>
                  {'Security > Set Up Phone Key '}
                </Typography>

                <Typography variant="body2">
                  Follow the instructions in the app and on the car's touchscreen.
                </Typography>
              </div>

              <div
                className={
                  cx(
                    'd-flex align-items-center justify-content-center gap-3',
                    windowWidth < parseInt(variables.sizemd, 10) ? 'flex-column' : 'flex-row',
                  )
                }
              >
                <img
                  src="/images/virtual-key/setup-phone-key/1.png"
                  alt="setup-phone-key-1"
                  style={{ width: windowWidth < parseInt(variables.sizemd, 10) ? '100%' : '50%' }}
                />
              </div>

              <div className="mt-2">
                <Typography variant="body2">
                  {'In-App Setup: Security > Set Up Phone Key'}
                </Typography>
              </div>

              <div>
                <img
                  src="https://s3-alpha-sig.figma.com/img/0936/92f8/d1fa126dfdf4d4d56ab9a8755c1b365d?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YmllhiWKsx~3oVe6ghP0nSonUeHALWda2FWfdZlQFOcDyRx1un7sbBVTXak7~3sFIrXDsQlbDsUd3VYl~N1BCYCEZH2C7BP2hdMU~rwiXK88fYOyOYM45EfbzDjQZ2YRb39HP25bL6X5PEO8YVavsO8aYJcsk-sbzfPJykWMLXubRftDTtGKYsBf5A5LApQyUlBKuKtA9H--mSkf1rGAMMVMap5CNJp-xh-S7pdX1f-qIsGFvjuCl3voDTBTBSDCoTB7FX1HFIqOBSatHsj6lCU9wywfPDDwS2eL2zz7FgjC6t3v9QRXNSmHyyo7m~o5CdsBCvSdGO9JdDbGLz6VAA__"
                  alt="setup-phone-key-gif"
                  style={{ width: '100%' }}
                />
              </div>

              <div className="mt-2">
                <Typography variant="body2">
                  Car setup. Note: You need to be physically near the car to enable this
                </Typography>
              </div>
            </div>

            <div
              className="p-3 mt-2 d-flex gap-2 flex-column"
              style={{ border: '1px solid #D5D5D5', borderRadius: '8px' }}
            >
              <Typography variant="body2" style={{ color: '#3370BA' }}>
                Step 3
              </Typography>

              <div>
                <Typography className="p-0">
                  To confirm your phone key is working:
                </Typography>

                <Typography>
                  • Open the Tesla app and ensure you can lock and unlock the car using your phone.
                </Typography>

                <Typography>
                  • You should see your phone key listed in the keys section on your cars screen
                </Typography>

                <div
                  className={
                    cx(
                      'd-flex align-items-center justify-content-center gap-3',
                      windowWidth < parseInt(variables.sizemd, 10) ? 'flex-column' : 'flex-row',
                    )
                  }
                >
                  <img
                    src="/images/virtual-key/setup-phone-key/2.png"
                    alt="setup-phone-key-2"
                    style={{ width: windowWidth < parseInt(variables.sizemd, 10) ? '100%' : '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between">
            <Button
              variant="default"
              onClick={() => {
                setShowConnectSteps(false);
              }}
            >
              Back
            </Button>

            <Button
              variant="primary"
              onClick={() => {
                setShowLastStep(true);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex flex-column gap-3">
        <div>
          <div className="d-flex flex-row gap-1">
            <Typography variant="h2" inline>
              Create & Connect Phone Key
              {' '}
            </Typography>

            <Typography variant="h2" inline>
              {'(est time <1min)'}
            </Typography>
          </div>

          <Typography variant="body2">
            {`${currCar?.model?.display_name} - ${currCar?.trim?.display_name} | License: ${currCar?.license_plate || ''} | Vin: ${currCar?.vin}`}
          </Typography>
        </div>

        <div className="d-flex flex-column gap-3">
          <Dialog text="You need to complete these steps for each car individually." />

          <Dialog text="You need to be the main owner for the car." />

          <Card style={{ backgroundColor: 'white', border: '1px solid #D5D5D5' }}>
            <Typography variant="h3">
              What is an Eon Key?
            </Typography>

            <Typography>
              Eon Keys are the way Tesla enables 3rd party systems (like Eon) to connect and communicate with your car.
              Data like mileage, charging, and vehicle control all pass through the Eon Key.
            </Typography>
          </Card>
        </div>

        <div className="d-flex flex-row justify-content-between">
          <Button
            variant="default"
            onClick={() => {
              setShowVirtualKeyModal(false);
              onDismiss?.();
            }}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setShowConnectSteps(true);
            }}
          >
            Next
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {
        showBanner && (
          <div
            className="d-flex flex-row gap-2"
            style={style}
          >
            <div style={{ fontSize: '20px' }}>
              ⚠️
            </div>
            <div>
              Car is not secured
            </div>
            <Button
              className="m-0 p-0"
              style={{ minWidth: '150px', height: '35px' }}
              onClick={() => setShowVirtualKeyModal(true)}
            >
              Add Digital Key
            </Button>
          </div>
        )
      }

      <Modal
        ariaHideApp={false}
        isOpen={showVirtualKeyModal}
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            zIndex: 1000,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '29px',
            width: '90%',
            borderRadius: '8px',
            // overflow: 'scroll',
            maxHeight: '90%',
            maxWidth: '700px',
          },
        }}
        onRequestClose={() => {
          onDismiss?.();
          setShowVirtualKeyModal(false);
          setShowConnectSteps(false);
          setShowLastStep(false);
          setShowReviewCars(false);
        }}
      >
        <div>
          <div ref={modalContentRef} className="d-flex justify-content-end">
            <Button
              onClick={() => {
                onDismiss?.();
                setShowVirtualKeyModal(false);
                setShowVirtualKeyModal(false);
                setShowConnectSteps(false);
                setShowLastStep(false);
                setShowReviewCars(false);
              }}
              style={{ width: '25px', height: '25px', padding: 0 }}
            >
              X
            </Button>
          </div>

          {
            renderBody()
          }
        </div>

      </Modal>
    </>
  );
};

export default AddVirtualKeyBanner;
